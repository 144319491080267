import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "src/environments/environment";

import {
  AuthService,
  CategoriesService,
  ChinelasService,
  ClientsService,
  ColorsService,
  CommissionsService,
  HeelTypesService,
  LastTypesService,
  LineTypesService,
  MarketTypesService,
  MovementTypesService,
  OrdersService,
  OrderStatusesService,
  OrderTypesService,
  OutletTypesService,
  PlazasService,
  PricingStandardsService,
  ProductService,
  ProductStatusesService,
  ProductionProcessesService,
  Products_OutletsService,
  Processes_WorkordersService,
  RawMaterialService,
  RolesService,
  SaleTypesService,
  SizesService,
  SoleTypesService,
  StagesService,
  UserService,
  WarehouseMovements_RawMaterialService,
  WarehousesService,
  Warehouse_RawMaterialService,
  WorkOrdersService,
  Products_PlazasService,
} from "src/services";

import { lastValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GetEverythingService {
  VALIDATE_TOKEN_URL = `${environment.apiUrl}/validatetoken/`;

  constructor(
    private http: HttpClient,

    private authService: AuthService,

    private chinelasService: ChinelasService,
    private clientsService: ClientsService,
    private commissionsService: CommissionsService,
    private marketTypesService: MarketTypesService,
    private movementTypesService: MovementTypesService,
    private orderService: OrdersService,
    private pricingStandardsService: PricingStandardsService,
    private productsService: ProductService,
    private products_outletsService: Products_OutletsService,
    private products_plazasService: Products_PlazasService,
    private rawMaterialsService: RawMaterialService,
    private sizesServices: SizesService,
    private usersService: UserService,
    private WMs_RMsService: WarehouseMovements_RawMaterialService,
    private warehouses_rawMaterialsService: Warehouse_RawMaterialService,
    private workOrderService: WorkOrdersService,
    private workOrdersProcesses: Processes_WorkordersService,

    private categoriesService: CategoriesService,
    private colorsService: ColorsService,
    private heelTypesService: HeelTypesService,
    private lastTypesService: LastTypesService,
    private lineTypesService: LineTypesService,
    private orderStatusesService: OrderStatusesService,
    private orderTypesService: OrderTypesService,
    private outletTypesService: OutletTypesService,
    private plazasService: PlazasService,
    private productionProcessesService: ProductionProcessesService,
    private productStatusesService: ProductStatusesService,
    private rolesService: RolesService,
    private saleTypesService: SaleTypesService,
    private soleTypesService: SoleTypesService,
    private stagesService: StagesService,
    private warehousesService: WarehousesService
  ) {
    this.getEverything();
  }

  async getEverything() {
    try {
      await lastValueFrom(this.http.get<any>(this.VALIDATE_TOKEN_URL));
    } catch (error) {
      this.authService.logout();
      return;
    }

    this.chinelasService.getChinelas();
    this.clientsService.getClients();
    this.commissionsService.getCommissions();
    this.marketTypesService.getMarketTypes();
    this.movementTypesService.getMovementTypes();
    this.orderService.getOrders();
    this.pricingStandardsService.getPricingStandards();
    this.productsService.getProducts();
    this.products_outletsService.getProduct_Outlets();
    this.products_plazasService.getProduct_Plazas();
    this.rawMaterialsService.getRawMaterials();
    this.sizesServices.getSizes();
    this.usersService.getUsers();
    this.WMs_RMsService.getWarehouseMovements_RawMaterials();
    this.warehouses_rawMaterialsService.getWarehouses_RawMaterials();
    this.workOrderService.getWorkOrders();
    this.workOrdersProcesses.getWorkOrderProcesses();

    this.categoriesService.getCategories();
    this.colorsService.getColors();
    this.heelTypesService.getHeelTypes();
    this.lastTypesService.getLastTypes();
    this.lineTypesService.getLineTypes();
    this.orderStatusesService.getOrderStatuses();
    this.orderTypesService.getOrderTypes();
    this.outletTypesService.getOutletTypes();
    this.plazasService.getPlazas();
    this.productionProcessesService.getProductionProcesses();
    this.productStatusesService.getProductStatuses();
    this.rolesService.getRoles();
    this.saleTypesService.getSaleTypes();
    this.soleTypesService.getSoleTypes();
    this.stagesService.getStages();
    this.warehousesService.getWarehouses();
  }
}
