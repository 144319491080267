import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { OutletType } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class OutletTypesService {
  OUTLET_TYPES_URL = `${environment.apiUrl}/outlettypes/`;

  outletTypes: any = [];

  public outletTypes$ = new BehaviorSubject<Array<any>>([]);

  constructor(private http: HttpClient) {}

  postOutletType(createOutletType: OutletType) {
    this.http.post(this.OUTLET_TYPES_URL, createOutletType).subscribe({
      next: (data: { msg: string; outletType: OutletType }) => {
        this.getOutletTypes();
      },
      error: (error) => {
        console.log(error.error.msg);
      },
    });
  }

  emitOutletTypes() {
    this.outletTypes$.next(this.outletTypes);
  }

  getOutletTypes() {
    this.http.get(this.OUTLET_TYPES_URL).subscribe((outletTypes: any) => {
      this.outletTypes = outletTypes;
      this.emitOutletTypes();
    });
  }

  putOutletType(editOutletType: OutletType) {
    this.http
      .put(this.OUTLET_TYPES_URL + editOutletType.id, editOutletType)
      .subscribe((data: { msg: string }) => {
        this.getOutletTypes();
      });
  }
}
