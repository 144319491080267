import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, lastValueFrom } from "rxjs";

import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class OrdersService {
  ORDERS_URL = `${environment.apiUrl}/orders/`;

  orders: any[] = [];

  public orders$ = new BehaviorSubject<Array<any>>([]);

  constructor(private http: HttpClient) {}

  postOrder(createOrder: any) {
    return this.http.post(this.ORDERS_URL, createOrder);
  }

  postDuplicateOrders(createDuplicates: any) {
    return this.http.post(`${this.ORDERS_URL}duplicates`, createDuplicates);
  }

  emitOrders() {
    this.orders$.next(this.orders);
  }

  getOrders() {
    this.http.get<any[]>(this.ORDERS_URL).subscribe((orders: any[]) => {
      this.orders = orders;
      this.emitOrders();
    });
  }

  getOrder(id: number) {
    return lastValueFrom(this.http.get(this.ORDERS_URL + id));
  }

  putOrder(editOrder: any) {
    return lastValueFrom(
      this.http.put(this.ORDERS_URL + editOrder.id, editOrder)
    );
  }

  putUploadImageOrder(img: any, orderId: number) {
    return lastValueFrom(
      this.http.put(`${this.ORDERS_URL}${orderId}/uploadImage`, img)
    );
  }

  deleteOrder(deleteOrder: number) {
    this.http
      .delete(this.ORDERS_URL + deleteOrder)
      .subscribe((data: { msg: string }) => {
        this.getOrders();
      });
  }
}
