import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "src/environments/environment";
import { lastValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class OperatorsPaymentsService {
  OPERATORS_PAYMENTS_URL = `${environment.apiUrl}/operatorspayments/`;

  constructor(private http: HttpClient) {}

  postOperatorsPayment(createOperatorsPayment: any) {
    return lastValueFrom(
      this.http.post(this.OPERATORS_PAYMENTS_URL, createOperatorsPayment)
    );
  }
}
