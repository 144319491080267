import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";
import { MarketType } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class MarketTypesService {
  MARKETTYPE_URL = `${environment.apiUrl}/markettypes/`;

  marketTypes: MarketType[] = [];

  public marketTypes$ = new BehaviorSubject<Array<MarketType>>([]);

  constructor(private http: HttpClient) {}

  postMarketType(createMarketType: MarketType) {
    this.http.post(this.MARKETTYPE_URL, createMarketType).subscribe({
      next: (data: { msg: string; marketType: MarketType }) => {
        this.getMarketTypes();
      },
      error: (error) => {
        console.log(error.error.msg);
      },
    });
  }

  emitMarketTypes() {
    this.marketTypes$.next(this.marketTypes);
  }

  getMarketTypes() {
    this.http
      .get<MarketType[]>(this.MARKETTYPE_URL)
      .subscribe((marketTypes: MarketType[]) => {
        this.marketTypes = marketTypes;
        this.emitMarketTypes();
      });
  }

  putMarketType(editMarketType: MarketType) {
    this.http
      .put(this.MARKETTYPE_URL + editMarketType.id, editMarketType)
      .subscribe((data: { msg: string }) => {
        this.getMarketTypes();
      });
  }

  deleteMarketType(deleteMarketType: number) {
    this.http
      .delete(this.MARKETTYPE_URL + deleteMarketType)
      .subscribe((data: { msg: string }) => {
        this.getMarketTypes();
      });
  }
}
