import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";
import { Stage } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class StagesService {
  STAGES_URL = `${environment.apiUrl}/stages/`;

  stages: Stage[] = [];

  public stages$ = new BehaviorSubject<Array<Stage>>([]);

  constructor(private http: HttpClient) {}

  postStage(createStage: Stage) {
    this.http.post(this.STAGES_URL, createStage).subscribe({
      next: (data: { msg: string; stage: Stage }) => {
        this.getStages();
      },
      error: (error) => {
        console.log(error.error.msg);
      },
    });
  }

  emitStages() {
    this.stages$.next(this.stages);
  }

  getStages() {
    this.http.get<Stage[]>(this.STAGES_URL).subscribe((stages: Stage[]) => {
      this.stages = stages;
      this.emitStages();
    });
  }

  putStage(editStage: Stage) {
    this.http
      .put(this.STAGES_URL + editStage.id, editStage)
      .subscribe((data: { msg: string }) => {
        this.getStages();
      });
  }

  deleteStage(deleteStage: number) {
    this.http
      .delete(this.STAGES_URL + deleteStage)
      .subscribe((data: { msg: string }) => {
        this.getStages();
      });
  }
}
