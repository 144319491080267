import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, lastValueFrom } from "rxjs";

import { environment } from "src/environments/environment";

import { WorkOrderProcess } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class Processes_WorkordersService {
  PROCESSES_WORKORDERS_URL = `${environment.apiUrl}/processes_workorders/`;

  workOrderProcesses: WorkOrderProcess[] = [];

  public workOrderProcesses$ = new BehaviorSubject<Array<WorkOrderProcess>>([]);

  constructor(private http: HttpClient) {}

  postWorkOrderProcess(createWorkOrderProcess: any) {
    return lastValueFrom(
      this.http.post(this.PROCESSES_WORKORDERS_URL, createWorkOrderProcess)
    );
  }

  emitWorkOrderProcesses() {
    this.workOrderProcesses$.next(this.workOrderProcesses);
  }

  getWorkOrderProcesses() {
    this.http
      .get<WorkOrderProcess[]>(this.PROCESSES_WORKORDERS_URL)
      .subscribe((workOrderProcesses: WorkOrderProcess[]) => {
        this.workOrderProcesses = workOrderProcesses;
        this.emitWorkOrderProcesses();
      });
  }

  putWorkOrderProcesses(workOrderProcesses: any) {
    return lastValueFrom(
      this.http.put(this.PROCESSES_WORKORDERS_URL, workOrderProcesses)
    );
  }
}
