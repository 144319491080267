import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserService {
  USERS_URL = `${environment.apiUrl}/users/`;

  users: any = [];

  public users$ = new BehaviorSubject<Array<any>>([]);

  constructor(private http: HttpClient) {}

  postUser(createUser: any) {
    return lastValueFrom(this.http.post(this.USERS_URL, createUser));
  }

  emitUsers() {
    this.users$.next(this.users);
  }

  getUsers() {
    this.http.get(this.USERS_URL).subscribe((users: any) => {
      this.users = users;
      this.emitUsers();
    });
  }

  getUser(id: number) {
    return lastValueFrom(this.http.get(this.USERS_URL + id));
  }

  putUser(editUser: any) {
    return lastValueFrom(this.http.put(this.USERS_URL + editUser.id, editUser));
  }

  deleteUser(deleteUser: number) {
    this.http.delete(this.USERS_URL + deleteUser).subscribe((users: any) => {
      this.users = users;
      this.getUsers();
    });
  }
}
