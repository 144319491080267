import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, lastValueFrom } from "rxjs";

import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class WorkOrdersService {
  WORKORDERS_URL = `${environment.apiUrl}/workorders/`;

  workOrders: any[] = [];

  public workOrders$ = new BehaviorSubject<Array<any>>([]);

  constructor(private http: HttpClient) {}

  emitWorkOrders() {
    this.workOrders$.next(this.workOrders);
  }

  getWorkOrders() {
    this.http.get<any[]>(this.WORKORDERS_URL).subscribe((workOrders: any[]) => {
      this.workOrders = workOrders;
      this.emitWorkOrders();
    });
  }

  getWorkOrder(id: number) {
    return lastValueFrom(this.http.get(this.WORKORDERS_URL + id));
  }

  //this will actually create a Process to that WorkOrder in Processes_WorkOrders table,
  //not update the WorkOrder
  putWorkOrder(workOrderProcess: any) {
    return lastValueFrom(this.http.put(this.WORKORDERS_URL, workOrderProcess));
  }

  finalizeOrder(workOrderStuff: any) {
    return lastValueFrom(
      this.http.post(this.WORKORDERS_URL + "finalize", workOrderStuff)
    );
  }
}
