import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";
import { ProductionProcess } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class ProductionProcessesService {
  PRODUCTION_PROCESSES_URL = `${environment.apiUrl}/productionprocesses/`;

  productionProcesses: ProductionProcess[] = [];

  public productionProcesses$ = new BehaviorSubject<Array<ProductionProcess>>(
    []
  );

  constructor(private http: HttpClient) {}

  postProductionProcess(createProductionProcess: ProductionProcess) {
    this.http
      .post(this.PRODUCTION_PROCESSES_URL, createProductionProcess)
      .subscribe({
        next: (data: { msg: string; productionProcess: ProductionProcess }) => {
          this.getProductionProcesses();
        },
        error: (error) => {
          console.log(error.error.msg);
        },
      });
  }

  emitProductionProcesses() {
    this.productionProcesses$.next(this.productionProcesses);
  }

  getProductionProcesses() {
    this.http
      .get<ProductionProcess[]>(this.PRODUCTION_PROCESSES_URL)
      .subscribe((productionProcesses: ProductionProcess[]) => {
        this.productionProcesses = productionProcesses;
        this.emitProductionProcesses();
      });
  }

  putProductionProcess(editProductionProcess: ProductionProcess) {
    this.http
      .put(
        this.PRODUCTION_PROCESSES_URL + editProductionProcess.id,
        editProductionProcess
      )
      .subscribe((data: { msg: string }) => {
        this.getProductionProcesses();
      });
  }

  deleteProductionProcess(deleteProductionProcess: number) {
    this.http
      .delete(this.PRODUCTION_PROCESSES_URL + deleteProductionProcess)
      .subscribe((data: { msg: string }) => {
        this.getProductionProcesses();
      });
  }
}
