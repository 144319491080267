import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";
import { Size } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class SizesService {
  SIZES_URL = `${environment.apiUrl}/sizes/`;

  sizes: Size[] = [];

  public sizes$ = new BehaviorSubject<Array<Size>>([]);

  constructor(private http: HttpClient) {}

  postSize(createSize: Size) {
    this.http.post(this.SIZES_URL, createSize).subscribe({
      next: (data: { msg: string; size: Size }) => {
        this.getSizes();
      },
      error: (error) => {
        console.log(error.error.msg);
      },
    });
  }

  emitSizes() {
    this.sizes$.next(this.sizes);
  }

  getSizes() {
    this.http.get<Size[]>(this.SIZES_URL).subscribe((sizes: Size[]) => {
      this.sizes = sizes;
      this.emitSizes();
    });
  }

  putSize(editSize: Size) {
    this.http
      .put(this.SIZES_URL + editSize.id, editSize)
      .subscribe((data: { msg: string }) => {
        this.getSizes();
      });
  }

  deleteSize(deleteSize: number) {
    this.http
      .delete(this.SIZES_URL + deleteSize)
      .subscribe((data: { msg: string }) => {
        this.getSizes();
      });
  }
}
