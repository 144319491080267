import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { RawMaterial } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class RawMaterialService {
  RAW_MATERIALS_URL = `${environment.apiUrl}/rawmaterials/`;

  rawMaterials: RawMaterial[] = [];

  public rawMaterials$ = new BehaviorSubject<Array<RawMaterial>>([]);

  constructor(private http: HttpClient) {}

  postRawMaterial(createRawMaterial: RawMaterial) {
    this.http.post(this.RAW_MATERIALS_URL, createRawMaterial).subscribe({
      next: (data: { msg: string; rawMaterial: RawMaterial }) => {
        this.getRawMaterials();
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  emitRawMaterials() {
    this.rawMaterials$.next(this.rawMaterials);
  }

  getRawMaterials() {
    this.http
      .get(this.RAW_MATERIALS_URL)
      .subscribe((rawMaterials: RawMaterial[]) => {
        this.rawMaterials = rawMaterials;
        this.emitRawMaterials();
      });
  }

  putRawMaterial(editRawMaterial: RawMaterial) {
    this.http
      .put(this.RAW_MATERIALS_URL + editRawMaterial.id, editRawMaterial)
      .subscribe((data: { msg: string }) => {
        this.getRawMaterials();
      });
  }

  deleteRawMaterial(deleteRawMaterial: number) {
    this.http
      .delete(this.RAW_MATERIALS_URL + deleteRawMaterial)
      .subscribe((data: { msg: string }) => {
        this.getRawMaterials();
      });
  }
}
