import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";
import { Warehouse } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class WarehousesService {
  WAREHOUSES_URL = `${environment.apiUrl}/warehouses/`;

  warehouses: Warehouse[] = [];

  public warehouses$ = new BehaviorSubject<Array<Warehouse>>([]);

  constructor(private http: HttpClient) {}

  emitWarehouses() {
    this.warehouses$.next(this.warehouses);
  }

  getWarehouses() {
    this.http
      .get<Warehouse[]>(this.WAREHOUSES_URL)
      .subscribe((warehouses: Warehouse[]) => {
        this.warehouses = warehouses;
        this.emitWarehouses();
      });
  }
}
