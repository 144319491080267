import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";
import { ProductStatus } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class ProductStatusesService {
  PRODUCT_STATUSES_URL = `${environment.apiUrl}/productstatuses/`;

  productStatuses: ProductStatus[] = [];

  public productStatuses$ = new BehaviorSubject<Array<ProductStatus>>([]);

  constructor(private http: HttpClient) {}

  postProductStatus(createProductStatus: ProductStatus) {
    this.http.post(this.PRODUCT_STATUSES_URL, createProductStatus).subscribe({
      next: (data: { msg: string; productStatus: ProductStatus }) => {
        this.getProductStatuses();
      },
      error: (error) => {
        console.log(error.error.msg);
      },
    });
  }

  emitProductStatuses() {
    this.productStatuses$.next(this.productStatuses);
  }

  getProductStatuses() {
    this.http
      .get<ProductStatus[]>(this.PRODUCT_STATUSES_URL)
      .subscribe((productStatuses: ProductStatus[]) => {
        this.productStatuses = productStatuses;
        this.emitProductStatuses();
      });
  }

  putProductStatus(editProductStatus: ProductStatus) {
    this.http
      .put(this.PRODUCT_STATUSES_URL + editProductStatus.id, editProductStatus)
      .subscribe((data: { msg: string }) => {
        this.getProductStatuses();
      });
  }

  deleteProductStatus(deleteProductStatus: number) {
    this.http
      .delete(this.PRODUCT_STATUSES_URL + deleteProductStatus)
      .subscribe((data: { msg: string }) => {
        this.getProductStatuses();
      });
  }
}
