import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

import { ROLES } from "src/app/utils";

@Injectable({
  providedIn: "root",
})
export class FactoryManagerGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean {
    const role: number = JSON.parse(localStorage.getItem("userData")).user?.Role
      ?.id;
    if (role !== ROLES.FACTORY_MANAGER) {
      return true;
    }

    this.router.navigate(["/dashboard"]);
    return false;
  }
}
