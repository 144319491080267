import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";
import { LineType } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class LineTypesService {
  LINETYPES_URL = `${environment.apiUrl}/linetypes/`;

  lineTypes: LineType[] = [];

  public lineTypes$ = new BehaviorSubject<Array<LineType>>([]);

  constructor(private http: HttpClient) {}

  postLineType(createLineType: LineType) {
    this.http.post(this.LINETYPES_URL, createLineType).subscribe({
      next: (data: { msg: string; lineType: LineType }) => {
        this.getLineTypes();
      },
      error: (error) => {
        console.log(error.error.msg);
      },
    });
  }

  emitLineTypes() {
    this.lineTypes$.next(this.lineTypes);
  }

  getLineTypes() {
    this.http
      .get<LineType[]>(this.LINETYPES_URL)
      .subscribe((lineTypes: LineType[]) => {
        this.lineTypes = lineTypes;
        this.emitLineTypes();
      });
  }

  putLineType(editLineType: LineType) {
    this.http
      .put(this.LINETYPES_URL + editLineType.id, editLineType)
      .subscribe((data: { msg: string }) => {
        this.getLineTypes();
      });
  }

  deleteLineType(deleteLineType: number) {
    this.http
      .delete(this.LINETYPES_URL + deleteLineType)
      .subscribe((data: { msg: string }) => {
        this.getLineTypes();
      });
  }
}
