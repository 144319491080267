import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, lastValueFrom } from "rxjs";

import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ReworkOrdersService {
  DECREASE_URL = `${environment.apiUrl}/reworkorders/`;

  reworkOrders: any[] = [];

  public reworkOrders$ = new BehaviorSubject<Array<any>>([]);

  constructor(private http: HttpClient) {}

  postReworkOrder(createReworkOrder: any) {
    return lastValueFrom(this.http.post(this.DECREASE_URL, createReworkOrder));
  }

  emitReworkOrders() {
    this.reworkOrders$.next(this.reworkOrders);
  }
}
