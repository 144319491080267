export const PRODUCTION_PROCESS = {
  CORTE: 1,
  MONTADO: 2,
  PLANTEADO_WELL: 3,
  PLANTEADO_A_MANO: 4,
  PUNTAS: 5,
  ENSUELADO_VAQUETA: 6,
  COCIDA: 7,
  PULIDO: 8,
  ADORNADO: 9,
  DESARMADO: 10,
  CINTO: 11,
  HUARACHE: 12,
  CARTERA: 13,
  HEBILLA_FORRADA: 14,
  CORBATA: 15,
  TOQUILLA: 16,
  TACONES: 17,
  HORMADO: 18,
  CASCOS_DE_SEGURIDAD: 19,
  MEDIA_VIDA: 20,
  FUNDA: 21,
  RINKLE: 22,
  ARGOLLAS: 23,
  ENSUELADO_HULE: 24,
  ENSUELADO_ESPUMA: 25,
  ENSUELADO_TRACTOR: 26,
  ENSUELADO_ALTA_DENSIDAD: 27,
  ENTACONADO: 28,
};
