import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";
import { OrderType } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class OrderTypesService {
  ORDER_URL = `${environment.apiUrl}/ordertypes/`;

  orderTypes: OrderType[] = [];

  public orderTypes$ = new BehaviorSubject<Array<OrderType>>([]);

  constructor(private http: HttpClient) {}

  postOrderType(createOrderType: OrderType) {
    this.http.post(this.ORDER_URL, createOrderType).subscribe({
      next: (data: { msg: string; orderType: OrderType }) => {
        this.getOrderTypes();
      },
      error: (error) => {
        console.log(error.error.msg);
      },
    });
  }

  emitOrderTypes() {
    this.orderTypes$.next(this.orderTypes);
  }

  getOrderTypes() {
    this.http
      .get<OrderType[]>(this.ORDER_URL)
      .subscribe((orderTypes: OrderType[]) => {
        this.orderTypes = orderTypes;
        this.emitOrderTypes();
      });
  }

  putOrderType(editOrderType: OrderType) {
    this.http
      .put(this.ORDER_URL + editOrderType.id, editOrderType)
      .subscribe((data: { msg: string }) => {
        this.getOrderTypes();
      });
  }

  deleteOrderType(deleteOrderType: number) {
    this.http
      .delete(this.ORDER_URL + deleteOrderType)
      .subscribe((data: { msg: string }) => {
        this.getOrderTypes();
      });
  }
}
