<nav class="navbar navbar-light bg-light">
  <a class="navbar-brand" href="#">
    <img
      src="../../assets/images/logo.png"
      width="70"
      height="50"
      class="d-inline-block align-middle"
      alt=""
    />
    Botas PYP
  </a>
  <button
    class="btn-ingreso d-inline-block align-center"
    (click)="openLarge(content1)"
  >
    Ingresar
  </button>
</nav>
<div class="slide-1-2">
  <div class="bg mision">
    <!-- Slider 1 -->
    <div class="mision-img">
      <div id="carousel-botas1" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner text-center">
          <div class="carousel-item active img-carousel">
            <img
              src="../../assets/images/slider1_1.jpg"
              alt="botas1"
              class="slide-1-2-img"
            />
          </div>
          <div class="carousel-item img-carousel">
            <img
              src="../../assets/images/slider1_2.jpg"
              alt="botas2"
              class="slide-1-2-img"
            />
          </div>
          <div class="carousel-item img-carousel">
            <img
              src="../../assets/images/slider1_3.jpg"
              alt="pieles"
              class="slide-1-2-img"
            />
          </div>
          <div class="carousel-item img-carousel">
            <img
              src="../../assets/images/slider1_4.jpg"
              alt="botas1"
              class="slide-1-2-img"
            />
          </div>
          <div class="carousel-item img-carousel">
            <img
              src="../../assets/images/slider1_5.jpg"
              alt="botas2"
              class="slide-1-2-img"
            />
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#carousel-botas1"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carousel-botas1"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
    <!-- Slider 1 end -->

    <!-- Slider 2 -->
    <div class="mision-img">
      <div id="carousel-botas2" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner text-center">
          <div class="carousel-item active img-carousel">
            <img
              src="../../assets/images/slider2_1.jpg"
              alt="botas1"
              class="slide-1-2-img"
            />
          </div>
          <div class="carousel-item img-carousel">
            <img
              src="../../assets/images/slider2_2.jpg"
              alt="botas2"
              class="slide-1-2-img"
            />
          </div>
          <div class="carousel-item img-carousel">
            <img
              src="../../assets/images/slider2_3.jpg"
              alt="pieles"
              class="slide-1-2-img"
            />
          </div>
          <div class="carousel-item img-carousel">
            <img
              src="../../assets/images/slider2_4.jpg"
              alt="botas1"
              class="slide-1-2-img"
            />
          </div>
          <div class="carousel-item img-carousel">
            <img
              src="../../assets/images/slider2_5.jpg"
              alt="botas2"
              class="slide-1-2-img"
            />
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#carousel-botas2"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carousel-botas2"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
    <!-- Slider 2 end -->
  </div>
</div>
<div class="mision">
  <div class="m-bg">
    <p class="mision-info">
      Somos una empresa 100% Chihuahuense, fabricantes y <br />
      distribuidores de bota éxotica de primera calidad. <br />
      Tenemos presencia en toda la República Méxicana y Estados Unidos.
      <br />
      <br />
      Nuestra prioridad es hacer un producto de alta calidad <br />
      en materiales y mano de obra, para que el cliente use <br />
      una bota cómoda, con clase y estilo.
    </p>
  </div>
  <!-- Slider 3 -->
  <div class="mision-img">
    <div id="carousel-botas3" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner text-center">
        <div class="carousel-item active img-carousel">
          <img src="../../assets/images/slider3_1.jpg" alt="botas1" />
        </div>
        <div class="carousel-item img-carousel">
          <img src="../../assets/images/slider3_2.jpg" alt="botas2" />
        </div>
        <div class="carousel-item img-carousel">
          <img src="../../assets/images/slider3_3.jpg" alt="pieles" />
        </div>
        <div class="carousel-item img-carousel">
          <img src="../../assets/images/slider3_4.jpg" alt="pieles" />
        </div>
        <div class="carousel-item img-carousel">
          <img src="../../assets/images/slider3_5.jpg" alt="pieles" />
        </div>
        <div class="carousel-item img-carousel">
          <img src="../../assets/images/slider3_6.jpg" alt="pieles" />
        </div>
      </div>
      <a
        class="carousel-control-prev"
        href="#carousel-botas3"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next"
        href="#carousel-botas3"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </div>
  <!-- Slider 3 end -->
</div>
<div class="bottom">
  <div class="sucursales">
    <p style="font-size: 32px; font-weight: 600; margin-bottom: 15px">
      Botas PYP
    </p>
    <p style="font-size: 16px; margin-bottom: 20px">
      El cliente es nuestro jefe.
    </p>
    <p style="font-size: 18px; margin-bottom: 10px">Sucursales:</p>
    <div class="info-sucursales">
      <p>Chihuahua. Avenida Teófilo Borunda #2708-D Zona centro</p>
      <p>
        <i
          style="font-size: 15px; margin-right: 15px"
          class="fas fa-phone-alt"
        ></i>
        614 197 2338
      </p>
      <p>
        <i
          style="font-size: 18px; margin-right: 18px"
          class="fab fa-instagram"
        ></i>
        <a href="https://www.instagram.com/botaspyp/" target="_blank"
          >@botaspyp</a
        >
      </p>
      <p>
        <i
          style="font-size: 18px; margin-right: 22px"
          class="fab fa-facebook-f"
        ></i
        ><a
          href="https://www.facebook.com/Botas-PyP-486038251923308"
          target="_blank"
          >Botaspyp</a
        >
      </p>

      <p style="margin-top: 30px">
        Cd. Juárez. Avenida Plutarco Elías Calles #400
      </p>
      <p>
        <i
          style="font-size: 15px; margin-right: 15px"
          class="fas fa-phone-alt"
        ></i>
        656 104 6884
      </p>
      <p>
        <i
          style="font-size: 18px; margin-right: 18px"
          class="fab fa-instagram"
        ></i
        ><a href="https://www.instagram.com/botaspyp/" target="_blank"
          >@botaspypcd.juarez</a
        >
      </p>
      <p>
        <i
          style="font-size: 18px; margin-right: 22px"
          class="fab fa-facebook-f"
        ></i
        ><a
          href="https://www.facebook.com/Botas-PyP-486038251923308"
          target="_blank"
          >Botaspypcd.juarez</a
        >
      </p>
      <p>
        <i
          style="font-size: 18px; margin-right: 16px"
          class="far fa-envelope"
        ></i
        ><a href="mailto:botaspyp@hotmail.com">botaspyp@hotmail.com</a>
      </p>
    </div>
  </div>
  <div class="pys">
    <div class="productos">
      <p style="font-size: 24px; font-weight: 600">Productos</p>
      <br />
      <p>Botas</p>
      <p>Cintos</p>
      <p>Carteras</p>
    </div>
    <div class="servicios">
      <p style="font-size: 24px; font-weight: 600">Servicios</p>
      <br />
      <p>Reparaciones</p>
    </div>
  </div>
</div>
<footer>&copy; 2021 Botas PYP. All Rights Reserved.</footer>

<!-- Modal -->
<ng-template #content1 let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5
      class="modal-title"
      id="exampleModalLabel"
      style="font-size: 32px; font-weight: 600; margin-left: 50px"
    >
      Inicio de sesión
    </h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="margin-bottom: 80px">
    <div class="login-modal">
      <div>
        <p style="color: red">{{ errorMsg }}</p>
      </div>
      <!-- Usuario -->
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">
            <i class="fas fa-user"> </i
          ></span>
        </div>
        <input
          type="text"
          class="form-control sm"
          placeholder="Usuario"
          aria-label="Usuario"
          aria-describedby="basic-addon1"
          (keydown.enter)="iniciarSesion()"
          [(ngModel)]="user.username"
        />
      </div>
      <!-- Pass -->
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1"
            ><i class="fas fa-unlock"></i
          ></span>
        </div>
        <input
          type="password"
          class="form-control sm"
          placeholder="Contraseña"
          aria-label="Contraseña"
          aria-describedby="basic-addon1"
          (keydown.enter)="iniciarSesion()"
          [(ngModel)]="user.password"
        />
      </div>
      <button class="btn-modal" (click)="iniciarSesion()">
        Iniciar sesión
      </button>
    </div>
    <div class="logo">
      <img src="../../assets/images/logo.png" alt="logo" />
    </div>
  </div>
</ng-template>
