import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class RolesService {
  ROLES_URL = `${environment.apiUrl}/roles/`;

  roles: any = [];

  public roles$ = new BehaviorSubject<Array<any>>([]);

  constructor(private http: HttpClient) {}

  getRoles() {
    this.http.get(this.ROLES_URL).subscribe((roles: any) => {
      this.roles = roles;
      this.emitRoles();
    });
  }

  emitRoles() {
    this.roles$.next(this.roles);
  }
}
