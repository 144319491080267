import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, lastValueFrom } from "rxjs";

import { Images } from "src/app/interfaces";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  PRODUCTS_URL = `${environment.apiUrl}/products/`;
  IMAGES_URL = `${environment.apiUrl}/images/`;

  products: any = [];

  public products$ = new BehaviorSubject<Array<any>>([]);

  constructor(private http: HttpClient) {}

  postProduct(createProduct: any) {
    return lastValueFrom(this.http.post(this.PRODUCTS_URL, createProduct));
  }

  emitProducts() {
    this.products$.next(this.products);
  }

  getProducts() {
    this.http.get(this.PRODUCTS_URL).subscribe((products: any) => {
      this.products = products;
      this.emitProducts();
    });
  }

  getProduct(id: number) {
    return lastValueFrom(this.http.get(this.PRODUCTS_URL + id));
  }

  putProduct(editProduct: any, productoAEditarId: number) {
    return lastValueFrom(
      this.http.put(this.PRODUCTS_URL + productoAEditarId, editProduct)
    );
  }

  deleteProduct(deleteProduct: number) {
    this.http
      .delete(this.PRODUCTS_URL + deleteProduct)
      .subscribe((product: any) => {
        this.getProducts();
      });
  }
}
