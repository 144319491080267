import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";

import { AuthService, GetEverythingService } from "src/services";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private getEverythingService: GetEverythingService
  ) {}

  canActivate(): boolean {
    if (this.authService.loggedIn()) {
      this.getEverythingService.getEverything();
      return true;
    }
    this.router.navigate(["/landing"]);
    return false;
  }
}
