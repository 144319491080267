import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { Warehouse_RawMaterial } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class Warehouse_RawMaterialService {
  WAREHOUSES_RAWMATERIALS_URL = `${environment.apiUrl}/warehouses_rawmaterials/`;

  warehouses_rawMaterials: Warehouse_RawMaterial[] = [];

  public warehouses_rawMaterials$ = new BehaviorSubject<
    Array<Warehouse_RawMaterial>
  >([]);

  constructor(private http: HttpClient) {}

  emitWarehouses_RawMaterials() {
    this.warehouses_rawMaterials$.next(this.warehouses_rawMaterials);
  }

  getWarehouses_RawMaterials() {
    this.http
      .get(this.WAREHOUSES_RAWMATERIALS_URL)
      .subscribe((warehouses_rawMaterials: Warehouse_RawMaterial[]) => {
        this.warehouses_rawMaterials = warehouses_rawMaterials;
        this.emitWarehouses_RawMaterials();
      });
  }
}
