import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, lastValueFrom } from "rxjs";

import { environment } from "src/environments/environment";

import { Product_Outlet } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class Products_OutletsService {
  PRODUCTS_OUTLETS_URL = `${environment.apiUrl}/products_outlets/`;

  products_outlets: Product_Outlet[] = [];

  public products_outlets$ = new BehaviorSubject<Array<Product_Outlet>>([]);

  constructor(private http: HttpClient) {}

  postProduct_Outlet(createProduct_Outlet: any) {
    return lastValueFrom(
      this.http.post(this.PRODUCTS_OUTLETS_URL, createProduct_Outlet)
    );
  }

  emitProduct_Outlets() {
    this.products_outlets$.next(this.products_outlets);
  }

  getProduct_Outlets() {
    this.http
      .get<Product_Outlet[]>(this.PRODUCTS_OUTLETS_URL)
      .subscribe((products_outlets: Product_Outlet[]) => {
        this.products_outlets = products_outlets;
        this.emitProduct_Outlets();
      });
  }
}
