import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, lastValueFrom } from "rxjs";

import { environment } from "src/environments/environment";
import { Commission } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class CommissionsService {
  COMISSIONS_URL = `${environment.apiUrl}/commissions/`;

  commissions: Commission[] = [];

  public commissions$ = new BehaviorSubject<Array<Commission>>([]);

  constructor(private http: HttpClient) {}

  emitCommissions() {
    this.commissions$.next(this.commissions);
  }

  getCommissions() {
    this.http
      .get<Commission[]>(this.COMISSIONS_URL)
      .subscribe((commissions: Commission[]) => {
        this.commissions = commissions;
        this.emitCommissions();
      });
  }

  putCommissions(commissions: any) {
    return lastValueFrom(this.http.put(this.COMISSIONS_URL, commissions));
  }
}
