import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { Plaza } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class PlazasService {
  PLAZAS_URL = `${environment.apiUrl}/plazas/`;

  plazas: any = [];

  public plazas$ = new BehaviorSubject<Array<any>>([]);

  constructor(private http: HttpClient) {}

  postPlaza(createPlaza: Plaza) {
    this.http.post(this.PLAZAS_URL, createPlaza).subscribe({
      next: (data: { msg: string; plaza: Plaza }) => {
        this.getPlazas();
      },
      error: (error) => {
        console.log(error.error.msg);
      },
    });
  }

  emitPlazas() {
    this.plazas$.next(this.plazas);
  }

  getPlazas() {
    this.http.get(this.PLAZAS_URL).subscribe((plazas: any) => {
      this.plazas = plazas;
      this.emitPlazas();
    });
  }

  putPlaza(editPlaza: Plaza) {
    this.http
      .put(this.PLAZAS_URL + editPlaza.id, editPlaza)
      .subscribe((data: { msg: string }) => {
        this.getPlazas();
      });
  }

  deletePlaza(deletePlaza: number) {
    this.http
      .delete(this.PLAZAS_URL + deletePlaza)
      .subscribe((data: { msg: string }) => {
        this.getPlazas();
      });
  }
}
