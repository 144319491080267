import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { Category } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class CategoriesService {
  CATEGORIES_URL = `${environment.apiUrl}/categories/`;

  categories: Category[] = [];

  public categories$ = new BehaviorSubject<Array<Category>>([]);

  constructor(private http: HttpClient) {}

  postCategory(createCategory: Category) {
    this.http.post(this.CATEGORIES_URL, createCategory).subscribe({
      next: (data: { msg: string; category: Category }) => {
        this.getCategories();
      },
      error: (error) => {
        console.log(error.error.msg);
      },
    });
  }

  emitCategories() {
    this.categories$.next(this.categories);
  }

  getCategories() {
    this.http
      .get<Category[]>(this.CATEGORIES_URL)
      .subscribe((categories: Category[]) => {
        this.categories = categories;
        this.emitCategories();
      });
  }

  putCategory(editCategory: Category) {
    this.http
      .put(this.CATEGORIES_URL + editCategory.id, editCategory)
      .subscribe((data: { msg: string }) => {
        this.getCategories();
      });
  }

  deleteCategory(deleteCategory: number) {
    this.http
      .delete(this.CATEGORIES_URL + deleteCategory)
      .subscribe((data: { msg: string }) => {
        this.getCategories();
      });
  }
}
