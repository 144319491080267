import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";
import { SaleType } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class SaleTypesService {
  SALETYPES_URL = `${environment.apiUrl}/saletypes/`;

  saleTypes: SaleType[] = [];

  public saleTypes$ = new BehaviorSubject<Array<SaleType>>([]);

  constructor(private http: HttpClient) {}

  postSaleType(createSaleType: SaleType) {
    this.http.post(this.SALETYPES_URL, createSaleType).subscribe({
      next: (data: { msg: string; saleType: SaleType }) => {
        this.getSaleTypes();
      },
      error: (error) => {
        console.log(error.error.msg);
      },
    });
  }

  emitSaleTypes() {
    this.saleTypes$.next(this.saleTypes);
  }

  getSaleTypes() {
    this.http
      .get<SaleType[]>(this.SALETYPES_URL)
      .subscribe((saleTypes: SaleType[]) => {
        this.saleTypes = saleTypes;
        this.emitSaleTypes();
      });
  }

  putSaleType(editSaleType: SaleType) {
    this.http
      .put(this.SALETYPES_URL + editSaleType.id, editSaleType)
      .subscribe((data: { msg: string }) => {
        this.getSaleTypes();
      });
  }

  deleteSaleType(deleteSaleType: number) {
    this.http
      .delete(this.SALETYPES_URL + deleteSaleType)
      .subscribe((data: { msg: string }) => {
        this.getSaleTypes();
      });
  }
}
