import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "src/environments/environment";
import { OutletRes } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class OutletsService {
  OUTLETS_URL = `${environment.apiUrl}/outlets/`;

  constructor(private http: HttpClient) {}

  postOutlet(createOutlet: OutletRes) {
    return this.http.post(this.OUTLETS_URL, createOutlet);
  }
}
