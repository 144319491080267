export const LAST_TYPES = {
  PATO: 1,
  TEXAS: 2,
  REDONDA: 3,
  CAMPER: 4,
  NACONA: 5,
  VERSACE: 6,
  PUNTA_CHIHUAHUA: 7,
  PUNTA_CANICA: 8,
};
