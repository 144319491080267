import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LandingPageComponent } from "./landing-page/landing-page.component";
import { AuthGuard } from "./auth.guard";
//import { AuthGuard } from "./modules/auth/_services/auth.guard";

export const routes: Routes = [
  {
    path: "landing",
    component: LandingPageComponent,
  },
  {
    path: "error",
    loadChildren: () =>
      import("./modules/errors/errors.module").then((m) => m.ErrorsModule),
  },
  {
    path: "",
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/layout.module").then((m) => m.LayoutModule),
    canActivate: [AuthGuard],
  },
  { path: "**", redirectTo: "error/404" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
