import { Injectable } from "@angular/core";

import { Paginator } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class PaginatorService {
  prevView = "";
  paginator: Paginator = { pageIndex: 0, pageSize: 50 };

  constructor() {}

  getPageIndex(view: string) {
    if (view === this.prevView) {
      return this.paginator;
    } else {
      this.setPageIndex({ pageIndex: 0, pageSize: 50, view });

      return this.paginator;
    }
  }

  setPageIndex({ pageIndex, pageSize, view }) {
    this.prevView = view;
    this.paginator.pageIndex = pageIndex;
    this.paginator.pageSize = pageSize;
  }
}
