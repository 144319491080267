import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { lastValueFrom } from "rxjs";

import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CountersService {
  COUNTERS_URL = `${environment.apiUrl}/counters/`;

  constructor(private http: HttpClient) {}

  putCounter(id: number, data: any) {
    return lastValueFrom(this.http.put(this.COUNTERS_URL + id, data));
  }
}
