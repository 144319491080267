import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";
import { Color } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class ColorsService {
  COLORS_URL = `${environment.apiUrl}/colors/`;

  colors: Color[] = [];

  public colors$ = new BehaviorSubject<Array<Color>>([]);

  constructor(private http: HttpClient) {}

  postColor(createColor: Color) {
    this.http.post(this.COLORS_URL, createColor).subscribe({
      next: (data: { msg: string; color: Color }) => {
        this.getColors();
      },
      error: (error) => {
        console.log(error.error.msg);
      },
    });
  }

  emitColors() {
    this.colors$.next(this.colors);
  }

  getColors() {
    this.http.get<Color[]>(this.COLORS_URL).subscribe((colors: Color[]) => {
      this.colors = colors;
      this.emitColors();
    });
  }

  putColor(editColor: Color) {
    this.http
      .put(this.COLORS_URL + editColor.id, editColor)
      .subscribe((data: { msg: string }) => {
        this.getColors();
      });
  }

  deleteColor(deleteColor: number) {
    this.http
      .delete(this.COLORS_URL + deleteColor)
      .subscribe((data: { msg: string }) => {
        this.getColors();
      });
  }
}
