import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, lastValueFrom } from "rxjs";

import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ChinelasService {
  CHINELAS_URL = `${environment.apiUrl}/chinelas/`;

  chinelas: any[] = [];

  public chinelas$ = new BehaviorSubject<Array<any>>([]);

  constructor(private http: HttpClient) {}

  postChinela(createChinela: any) {
    return lastValueFrom(this.http.post(this.CHINELAS_URL, createChinela));
  }

  emitChinelas() {
    this.chinelas$.next(this.chinelas);
  }

  getChinelas() {
    this.http.get<any[]>(this.CHINELAS_URL).subscribe((chinelas: any[]) => {
      this.chinelas = chinelas;
      this.emitChinelas();
    });
  }

  getChinela(id: number) {
    return lastValueFrom(this.http.get(this.CHINELAS_URL + id));
  }

  putChinela(editChinela: any) {
    return lastValueFrom(
      this.http.put(this.CHINELAS_URL + editChinela.id, editChinela)
    );
  }

  putUploadImageChinela(img: any, chinelaId: number) {
    return lastValueFrom(
      this.http.put(`${this.CHINELAS_URL}${chinelaId}/uploadImage`, img)
    );
  }

  deleteChinela(deleteChinela: number) {
    this.http
      .delete(this.CHINELAS_URL + deleteChinela)
      .subscribe((data: { msg: string }) => {
        this.getChinelas();
      });
  }
}
