import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
  HostListener,
} from "@angular/core";
import { AuthService } from "src/services";

// language list
import { SplashScreenService } from "./_metronic/partials/layout/splash-screen/splash-screen.service";
import { Router, NavigationEnd } from "@angular/router";
import { Subscription } from "rxjs";
import { TableExtendedService } from "./_metronic/shared/crud-table";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "body[root]",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  //NodeJS.Timeout
  timerToResetVariable: any;
  timerToRefreshToken: any;
  timePassed: any = 0;

  DIEZ_MINUTOS = 600000;
  SEGUNDO = 1000;

  constructor(
    private splashScreenService: SplashScreenService,
    private router: Router,
    private tableService: TableExtendedService,
    private authService: AuthService
  ) {
    this.timeOutForTimePassed();
    this.timeOutForRefreshToken();
  }

  timeOutForTimePassed() {
    this.timerToResetVariable = setInterval(() => {
      this.timePassed += this.SEGUNDO;
    }, this.SEGUNDO);
  }

  timeOutForRefreshToken() {
    this.timerToRefreshToken = setInterval(() => {
      this.checkForTokenRefresh();
    }, this.DIEZ_MINUTOS);
  }

  async checkForTokenRefresh() {
    if (this.timePassed < this.DIEZ_MINUTOS) await this.refreshToken();
    else this.authService.logout();
  }

  @HostListener("window:keydown")
  @HostListener("window:mousedown")
  @HostListener("window:mousemove")
  @HostListener("window:mousewheel")
  checkUserActivity() {
    this.timePassed = 0;
  }

  ngOnInit() {
    this.refreshToken();

    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // clear filtration paginations and others
        this.tableService.setDefaults();
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add("page-loaded");
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  async refreshToken() {
    await this.authService.refreshSession();
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
