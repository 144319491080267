import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";
import { OrderStatus } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class OrderStatusesService {
  ORDER_STATUSES_URL = `${environment.apiUrl}/orderstatuses/`;

  orderStatuses: OrderStatus[] = [];

  public orderStatuses$ = new BehaviorSubject<Array<OrderStatus>>([]);

  constructor(private http: HttpClient) {}

  emitOrderStatuses() {
    this.orderStatuses$.next(this.orderStatuses);
  }

  getOrderStatuses() {
    this.http
      .get<OrderStatus[]>(this.ORDER_STATUSES_URL)
      .subscribe((orderStatuses: OrderStatus[]) => {
        this.orderStatuses = orderStatuses;
        this.emitOrderStatuses();
      });
  }
}
