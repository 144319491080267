import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";
import { LastType } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class LastTypesService {
  LASTTYPES_URL = `${environment.apiUrl}/lasttypes/`;

  lastTypes: LastType[] = [];

  public lastTypes$ = new BehaviorSubject<Array<LastType>>([]);

  constructor(private http: HttpClient) {}

  postLastType(createLastType: LastType) {
    this.http.post(this.LASTTYPES_URL, createLastType).subscribe({
      next: (data: { msg: string; lastType: LastType }) => {
        this.getLastTypes();
      },
      error: (error) => {
        console.log(error.error.msg);
      },
    });
  }

  emitLastTypes() {
    this.lastTypes$.next(this.lastTypes);
  }

  getLastTypes() {
    this.http
      .get<LastType[]>(this.LASTTYPES_URL)
      .subscribe((lastTypes: LastType[]) => {
        this.lastTypes = lastTypes;
        this.emitLastTypes();
      });
  }

  putLastType(editLastType: LastType) {
    this.http
      .put(this.LASTTYPES_URL + editLastType.id, editLastType)
      .subscribe((data: { msg: string }) => {
        this.getLastTypes();
      });
  }

  deleteLastType(deleteLastType: number) {
    this.http
      .delete(this.LASTTYPES_URL + deleteLastType)
      .subscribe((data: { msg: string }) => {
        this.getLastTypes();
      });
  }
}
