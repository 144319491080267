import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";
import { Client } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class ClientsService {
  CLIENTS_URL = `${environment.apiUrl}/clients/`;

  clients: Client[] = [];

  public clients$ = new BehaviorSubject<Array<Client>>([]);

  constructor(private http: HttpClient) {}

  emitClients() {
    this.clients$.next(this.clients);
  }

  getClients() {
    this.http.get<Client[]>(this.CLIENTS_URL).subscribe((clients: Client[]) => {
      this.clients = clients;
      this.emitClients();
    });
  }
}
