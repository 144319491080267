import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class WarehouseMovementService {
  WAREHOUSE_MOVEMENTS_URL = `${environment.apiUrl}/warehousemovements/`;

  warehouseMovements: any = [];

  public warehouseMovements$ = new BehaviorSubject<Array<any>>([]);

  constructor(private http: HttpClient) {}

  postWarehouseMovement(createWarehouseMovement: any) {
    return this.http.post(
      this.WAREHOUSE_MOVEMENTS_URL,
      createWarehouseMovement
    );
  }

  emitWarehouseMovements() {
    this.warehouseMovements$.next(this.warehouseMovements);
  }

  getWarehouseMovements() {
    this.http
      .get(this.WAREHOUSE_MOVEMENTS_URL)
      .subscribe((warehouseMovements: any) => {
        this.warehouseMovements = warehouseMovements;
        this.emitWarehouseMovements();
      });
  }
}
