export const RAW_MATERIALS = {
  TUBO: 29,
  TUBO_DAMA: 275,
  FORRO: 263,
  CONTRAS: 262,
  VIVO: 30,
  ZIPPER: 229,
  PLANTILLAS: 31,
  REFUERZO: 32,
  CASCOS_DE_PUNTA: 33,
  CASCOS_DE_SEGURIDAD: 34,
  RYP: 35,
  ANTIFAZ: 250,
  ARGOLLA: 251,
  SUELA_VAQUETA: 37,
  SUELA_HULE: 38,
  SUELA_TRACTOR: 40,
  TACONES: 44,
  TAPA_ECONOMICA: 261,
  TAPA_LT_NEGRA: 45,
  TAPA_LT_NATURALES: 46,
  CAMBRELLON: 47,
  HERRADURA: 48,
  MEDIA_VIDA_NEGRO: 42,
  MEDIA_VIDA_NATURAL: 43,
  BASES: 49,
};
