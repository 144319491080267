import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";
import { PricingStandard } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class PricingStandardsService {
  PRICINGSTANDARDS_URL = `${environment.apiUrl}/pricingstandards/`;

  pricingStandards: PricingStandard[] = [];

  public pricingStandards$ = new BehaviorSubject<Array<PricingStandard>>([]);

  constructor(private http: HttpClient) {}

  postPricingStandard(createPricingStandard: PricingStandard) {
    this.http.post(this.PRICINGSTANDARDS_URL, createPricingStandard).subscribe({
      next: (data: { msg: string; pricingStandard: PricingStandard }) => {
        this.getPricingStandards();
      },
      error: (error) => {
        console.log(error.error.msg);
      },
    });
  }

  emitPricingStandards() {
    this.pricingStandards$.next(this.pricingStandards);
  }

  getPricingStandards() {
    this.http
      .get<PricingStandard[]>(this.PRICINGSTANDARDS_URL)
      .subscribe((pricingStandards: PricingStandard[]) => {
        this.pricingStandards = pricingStandards;
        this.emitPricingStandards();
      });
  }

  putPricingStandard(editPricingStandard: PricingStandard) {
    this.http
      .put(
        this.PRICINGSTANDARDS_URL + editPricingStandard.id,
        editPricingStandard
      )
      .subscribe((data: { msg: string }) => {
        this.getPricingStandards();
      });
  }

  deletePricingStandard(deletePricingStandard: number) {
    this.http
      .delete(this.PRICINGSTANDARDS_URL + deletePricingStandard)
      .subscribe((data: { msg: string }) => {
        this.getPricingStandards();
      });
  }
}
