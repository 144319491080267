import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";
import { SoleType } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class SoleTypesService {
  SOLETYPES_URL = `${environment.apiUrl}/soletypes/`;

  soleTypes: SoleType[] = [];

  public soleTypes$ = new BehaviorSubject<Array<SoleType>>([]);

  constructor(private http: HttpClient) {}

  postSoleType(createSoleType: SoleType) {
    this.http.post(this.SOLETYPES_URL, createSoleType).subscribe({
      next: (data: { msg: string; soleType: SoleType }) => {
        this.getSoleTypes();
      },
      error: (error) => {
        console.log(error.error.msg);
      },
    });
  }

  emitSoleTypes() {
    this.soleTypes$.next(this.soleTypes);
  }

  getSoleTypes() {
    this.http
      .get<SoleType[]>(this.SOLETYPES_URL)
      .subscribe((soleTypes: SoleType[]) => {
        this.soleTypes = soleTypes;
        this.emitSoleTypes();
      });
  }

  putSoleType(editSoleType: SoleType) {
    this.http
      .put(this.SOLETYPES_URL + editSoleType.id, editSoleType)
      .subscribe((data: { msg: string }) => {
        this.getSoleTypes();
      });
  }

  deleteSoleType(deleteSoleType: number) {
    this.http
      .delete(this.SOLETYPES_URL + deleteSoleType)
      .subscribe((data: { msg: string }) => {
        this.getSoleTypes();
      });
  }
}
