export const PRODUCT_STATUS = {
  STOCK: 1,
  PEDIDO: 2,
  REMONTA: 3,
  SIN_RECIBIR: 4,
  MERMA: 5,
  VENTA: 6,
  VENTA_DEMANDA: 7,
  VENTA_REMONTA: 8,
  VENTA_PENDIENTE: 9,
};
