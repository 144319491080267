import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";
import { MovementType } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class MovementTypesService {
  MOVEMENTTYPES_URL = `${environment.apiUrl}/movementtypes/`;

  movementTypes: MovementType[] = [];

  public movementTypes$ = new BehaviorSubject<Array<MovementType>>([]);

  constructor(private http: HttpClient) {}

  emitMovementTypes() {
    this.movementTypes$.next(this.movementTypes);
  }

  getMovementTypes() {
    this.http
      .get<MovementType[]>(this.MOVEMENTTYPES_URL)
      .subscribe((movementTypes: MovementType[]) => {
        this.movementTypes = movementTypes;
        this.emitMovementTypes();
      });
  }
}
