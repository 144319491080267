import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";

import { AuthService } from "src/services";

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
})
export class LandingPageComponent implements OnInit {
  user: { username: string; password: string } = {
    username: null,
    password: null,
  };

  errorMsg: string;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private authService: AuthService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  // Modal
  openLarge(content: any) {
    this.modalService.open(content, {
      size: "lg",
    });
  }

  async iniciarSesion() {
    const user = await this.authService.loginUser(this.user);
    if (user?.error?.msg) {
      this.errorMsg = user.error.msg;
    } else {
      this.modalService.dismissAll();
      this.router.navigateByUrl("/dashboard");
    }
    this.cd.markForCheck();
  }
}
