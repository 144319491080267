import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { lastValueFrom } from "rxjs";

import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private AUTH_URL = `${environment.apiUrl}/auth`;

  user: any;

  constructor(private http: HttpClient, private router: Router) {}

  async loginUser(user: { username: string; password: string }) {
    this.user = null;

    try {
      this.user = await lastValueFrom(
        this.http.post<any>(`${this.AUTH_URL}/login`, user)
      );
      localStorage.setItem("userData", JSON.stringify(this.user));

      return this.user;
    } catch (error) {
      return error;
    }
  }

  loggedIn() {
    const userDataString = localStorage.getItem("userData");
    const userData = JSON.parse(userDataString);
    return !!userData?.token;
  }

  logout() {
    localStorage.clear();
    this.router.navigate(["/landing"]);
  }

  getToken() {
    const userData: any = JSON.parse(localStorage.getItem("userData"));
    return userData?.token;
  }

  getTokenExpiration() {
    const userData: any = JSON.parse(localStorage.getItem("userData"));
    return userData?.exp;
  }

  async refreshSession() {
    const localData = JSON.parse(localStorage.getItem("userData"));

    if (!localData) return;

    const resp = await lastValueFrom(
      this.http.post<any>(`${this.AUTH_URL}/refreshsession`, {
        id: localData?.user?.id,
      })
    );

    if (!resp) return;

    const newData = { exp: resp.exp, token: resp.token, user: localData.user };

    localStorage.setItem("userData", JSON.stringify(newData));
  }
}
