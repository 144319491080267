import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";
import { HeelType } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class HeelTypesService {
  HEELTYPES_URL = `${environment.apiUrl}/heeltypes/`;

  heelTypes: HeelType[] = [];

  public heelTypes$ = new BehaviorSubject<Array<HeelType>>([]);

  constructor(private http: HttpClient) {}

  postHeelType(createHeelType: HeelType) {
    this.http.post(this.HEELTYPES_URL, createHeelType).subscribe({
      next: (data: { msg: string; heelType: HeelType }) => {
        this.getHeelTypes();
      },
      error: (error) => {
        console.log(error.error.msg);
      },
    });
  }

  emitHeelTypes() {
    this.heelTypes$.next(this.heelTypes);
  }

  getHeelTypes() {
    this.http
      .get<HeelType[]>(this.HEELTYPES_URL)
      .subscribe((heelTypes: HeelType[]) => {
        this.heelTypes = heelTypes;
        this.emitHeelTypes();
      });
  }

  putHeelType(editHeelType: HeelType) {
    this.http
      .put(this.HEELTYPES_URL + editHeelType.id, editHeelType)
      .subscribe((data: { msg: string }) => {
        this.getHeelTypes();
      });
  }

  deleteHeelType(deleteHeelType: number) {
    this.http
      .delete(this.HEELTYPES_URL + deleteHeelType)
      .subscribe((data: { msg: string }) => {
        this.getHeelTypes();
      });
  }
}
