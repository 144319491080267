export const ORDER_STATUS = {
  CREADO: 1,
  CONFIRMADO: 2,
  EN_FABRICA: 3,
  CORTE: 4,
  MONTADO: 5,
  ENSUELADO: 6,
  PLANTEADO: 7,
  DESARMADO: 8,
  ADORNADO: 9,
  FINALIZADO: 10,
  CANCELADO: 11,
  PULIDO: 12,
  COCIDA: 13,
  HORMADO: 14,
  ENTACONADO: 15,
  CORBATAS: 16,
};
