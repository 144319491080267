import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class Products_PlazasService {
  PRODUCTS_PLAZAS_URL = `${environment.apiUrl}/products_plazas/`;

  products_plazas: any[] = [];

  public products_plazas$ = new BehaviorSubject<Array<any>>([]);

  constructor(private http: HttpClient) {}

  emitProduct_Plazas() {
    this.products_plazas$.next(this.products_plazas);
  }

  getProduct_Plazas() {
    this.http
      .get<any[]>(this.PRODUCTS_PLAZAS_URL)
      .subscribe((products_plazas: any[]) => {
        this.products_plazas = products_plazas;
        this.emitProduct_Plazas();
      });
  }
}
