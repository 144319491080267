import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

import { environment } from "src/environments/environment";

import { WarehouseMovements_RawMaterial } from "src/app/interfaces";

@Injectable({
  providedIn: "root",
})
export class WarehouseMovements_RawMaterialService {
  WAREHOUSEMOVEMENTS_RAWMATERIALS_URL = `${environment.apiUrl}/warehousemovements_rawmaterials/`;

  warehouses_rawMaterials: WarehouseMovements_RawMaterial[] = [];

  public warehouseMovements_rawMaterials$ = new BehaviorSubject<
    Array<WarehouseMovements_RawMaterial>
  >([]);

  constructor(private http: HttpClient) {}

  emitWarehouseMovements_RawMaterials() {
    this.warehouseMovements_rawMaterials$.next(this.warehouses_rawMaterials);
  }

  getWarehouseMovements_RawMaterials() {
    this.http
      .get(this.WAREHOUSEMOVEMENTS_RAWMATERIALS_URL)
      .subscribe(
        (warehouses_rawMaterials: WarehouseMovements_RawMaterial[]) => {
          this.warehouses_rawMaterials = warehouses_rawMaterials;
          this.emitWarehouseMovements_RawMaterials();
        }
      );
  }
}
